import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';

import Section from '$ui/section';
import Acts from '$components/Acts';

const actsData = [
  {
    id: 0,
    title: 'Consumer Protection Council',
    description: `The Minister of Consumer Affairs, - Chairman, and Such number of other official or non-official members representing such interests as may be prescribed.The Minister in charge of consumer affairs in the State Government- Chairman.
      Such number of other official or non-official members representing such interests as may be prescribed by the State Government.
      Such number of other official or non-official members, not exceeding ten, as may be nominated by the Central Government.
      The State Council is required to meet as and when necessary but not less than two meetings every year.`
  },

  {
    id: 1,
    title: 'Consumer Disputes Redressal Agencies',
    description: `Consumer Court is the special purpose court, mainly in India, that deals with cases regarding consumer disputes and grievances. These are judiciary set ups by the government to0
    protect the consumer rights. Its main function is to maintain the fair practices by the sellers towards consumers. Consumers can file a case against a seller if they are harassed or
    exploited by sellers. The court will only give a verdict in favor of the consumers/customers if they have proof of exploitation, i.e, bills or other documents. If a consumer does not have
    the proper documents required for filing a case then it would be very difficult for the consumer to win or even file a case
    Types of Consumer Courts
    National Consumer Disputes Redressal Commission (NCDRC): A nation level court works for the whole country and deals with amount more than 1 crore (US$182,000).
    State Consumer Disputes Redressal Commission (SCDRC): A state level court works at the state level with cases valuing less than 1 crore (US$182,000).
    .District Consumer Disputes Redressal Forum (DCDRF): A district level court works at the district level with cases valuing up to 20 lakh (US$36,400).
    Consumer movement
    The consumer movement as a social force originated with the necessity of protecting and promoting the interests of consumers against unethical and untfair trade practices.
    Rampant food shortages, hoarding, black marketing, adulteration of food and edible oil gave birth to the consumer movement in an organized form in the 1960s. The movement
    succeeded in bringing pressure. A major step was taken in 1986 by the Indian government with the enactment of the Consumer Protection Act of 1986 (COPRA).`
  },

  {
    id: 3,
    title: 'Objectives',
    description: `The right to be protected against the marketing of goods and services which are hazardous to life and property.
    The right to be informed about the quality., quantity, potency, purity, standard and price of goods or services, as the case may be so as to protect the consumer against unfai
    trade practices.
    The right to be assured, wherever possible, access to a variety of goods and services at competitive prices.
    The right to be heard and to be assured that consumer's interests will receive due consideration at appropriate forums
    The right to seek redressal against unfair trade practices or restrictive trade practices or unscrupulous exploitation of con-sumers; and
    the right to consumer education.`
  },

  {
    id: 4,
    title: 'Jurisdiction',
    description: `(a)- the opposite party or each of the opposite parties, where there are more than one, at the time of the institution of the complaint, actually and voluntarily resides or carries on
    business or has a branch office or personally works for gain, or (b)- any of the opposite parties, where there are more than one, at the time of the institution of the complaint, actually and voluntarily resides, or carries on business or has a
    branch office, or personally works for gain, provided that in such case either the permission of the District Forum is given, or the opposite parties who do not reside, or carry on
    business or have a branch office, or personally work for gain, as the case may be, acquiesce in such institution; or
    (Cthe cause of action, wholly or in part, arises.`
  },

  {
    id: 5,
    title: 'Jurisdiction Of State Council',
    description: `(a) - to entertaini-Complaints where the value of the goods or services and compensation, if any, claimed exceeds rupees twenty lakhs but does not exceed rupees one crore; and
    .ii-appeals against the orders of any District Forum within the State and (b) - to call for the records and pass appropriate orders in any con-sumer dispute which is pending before or has been decided by any District Forum within the State, where it
    appears to the State Commission that such District Forum has exercised a jurisdiction not vested in it by law, or has failed to exercise a jurisdiction so vested or has acted in
    exercise of its jurisdiction illegally or with material irregularity.`
  },

  {
    id: 6,
    title: 'Jurisdiction Of National Council',
    description: `(a)-to entertainI-Complaints where the value of the goods or services and compensation, If any, claimed exceeds rupees one crore; and
    ii - appeals against the orders of any State Commission (b)-to call for the records and pass appropriate orders in any consumer dispute which is pending before or has been decided by any State Commission where it appears to the
    National Commission that such State Commission has exercised a jurisdiction not vested in it by law, or has failed to exercise a jurisdiction so vested, or has acted in the exercise
    of its jurisdiction illegally or with material irregularity.`
  }
];

const ConsumerActPage = () => {
  const [faqs, setFaqs] = useState(actsData);

  useCallback(e => {
    setFaqs(
      actsData.filter(f =>
        `${f.description} ${f.title}`.includes(e.target.value)
      )
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>TheCRO &mdash; ConsumerActs</title>
      </Helmet>

      <main>
        <Section id='consumerActs' className='bg-gray-100'>
          <div className=' mt-32 bg-white  w-4/5 mx-auto -mt-40 shadow-md rounded-md overflow-x-hidden phone:w-11/12'>
            {faqs.map(f => (
              <Acts
                key={f.id}
                id={f.id}
                title={f.title}
                description={f.description}
              />
            ))}
          </div>
        </Section>
      </main>
    </>
  );
};
export default ConsumerActPage;
